import React from 'react';
import '../assets/css/contactMenu.css';
import instagramLogo from '../assets/img/instagram-icon.svg';
import whatsappLogo from '../assets/img/whatsapp-icon.svg';
import facebookLogo from '../assets/img/facebook-icon.svg';
import tiktokLogo from '../assets/img/tiktok-icon.svg';
import telegramLogo from '../assets/img/telegram-icon.svg';
import viberLogo from '../assets/img/viber-icon.svg';

function ContactMenu() {
    return (
        <div className="contact-menu">
            <div className="contact-section">
                <a href="https://wa.me/+380988896977" target="_blank" rel="noopener noreferrer">
                    <img src={whatsappLogo} alt="WhatsApp" />
                </a>
                <a href="https://t.me/+380988896977" target="_blank" rel="noopener noreferrer">
                    <img src={telegramLogo} alt="Telegram" />
                </a>
                <a href="viber://chat?number=%2B380988896977" target="_blank" rel="noopener noreferrer">
                    <img src={viberLogo} alt="Viber" />
                </a>
            </div>
            <div className="contact-section">
                <a href="https://www.tiktok.com/@polissya.granite7?_t=8oe1nZn7O1m&_r=1" target="_blank" rel="noopener noreferrer">
                    <img src={tiktokLogo} alt="TikTok" />
                </a>
                <a href="https://www.facebook.com/share/8p9J7mTfC4k9cxv4/" target="_blank" rel="noopener noreferrer">
                    <img src={facebookLogo} alt="Facebook" />
                </a>
                <a href="https://www.instagram.com/polissya_granite_ua?igsh=MWcwYzI4YjZnd3VjdA==" target="_blank" rel="noopener noreferrer">
                    <img src={instagramLogo} alt="Instagram" />
                </a>
            </div>
        </div>
    );
}

export default ContactMenu;
